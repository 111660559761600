'use client';

import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';

export function getApiUrl() {
  return process.env.NEXT_PUBLIC_BACKEND_API_URL === '' ||
    !process.env.NEXT_PUBLIC_BACKEND_API_URL ||
    process.env.NEXT_PUBLIC_BACKEND_API_URL === undefined
    ? ''
    : process.env.NEXT_PUBLIC_BACKEND_API_URL;
}

export function initMonitoring() {
  if (process.env.NEXT_PUBLIC_DATADOG_APP_ID === undefined) {
    return;
  }
  const application = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_NAME;
  const environment = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ENV;
  const app_version = process.env.NEXT_PUBLIC_DATADOG_APPLICATION_VERSION;
  const datadog_application_id = process.env.NEXT_PUBLIC_DATADOG_APP_ID;
  const datadog_client_token = process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN!;

  datadogRum.init({
    applicationId: datadog_application_id,
    clientToken: datadog_client_token,
    service: application,
    env: environment,
    version: app_version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    telemetrySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'allow',
    useSecureSessionCookie: true,
    proxy: (options) =>
      `${getApiUrl()}/dd-intake${options.path}/ra?${options.parameters}`,
  });

  datadogLogs.init({
    clientToken: datadog_client_token,
    service: application,
    env: environment,
    version: app_version,
    forwardErrorsToLogs: true,
    forwardConsoleLogs: 'all',
    forwardReports: 'all',
    sessionSampleRate: 100,
    telemetrySampleRate: 100,
    useSecureSessionCookie: true,
    proxy: (options) =>
      `${getApiUrl()}/dd-intake${options.path}/ra?${options.parameters}`,
  });

  // datadogRum.startSessionReplayRecording();
}

export default function DatadogInit() {
  initMonitoring();
  // Render nothing - this component is only included so that the init code
  // above will run client-side
  return null;
}
