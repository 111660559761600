'use client';
import { Inter } from 'next/font/google';
import { ColorSchemeScript, MantineProvider } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import QueryProvider from '@/components/QueryProvider';
import { JotaiProvider } from '@/components/JotaiProvider';

import '@mantine/core/styles.css';
import '@mantine/notifications/styles.css';
import './globals.css';
import DatadogInit from '@/components/DatadogInit';
import { Suspense } from 'react';

const inter = Inter({ subsets: ['latin'] });

export const dynamic = 'force-dynamic'; // defaults to auto

export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en">
      <head>
        <ColorSchemeScript />
        <link rel="icon" href="favicon.png" sizes="any" />
        <meta name="title" content="EV Planning Hub" />
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, user-scalable=no"
        />
        <meta name="robots" content="none" />
      </head>
      <body className={`${inter.className} h-full overflow-hidden bg-gray-50`}>
        <DatadogInit />
        <QueryProvider>
          <JotaiProvider>
            <MantineProvider
              theme={{
                fontFamily: `${inter.style.fontFamily}, sans-serif`,
                headings: {
                  fontFamily: `${inter.style.fontFamily}, sans-serif`,
                },
              }}
              defaultColorScheme="light"
            >
              <Suspense>{children}</Suspense>
              <Notifications />
            </MantineProvider>
          </JotaiProvider>
        </QueryProvider>
      </body>
    </html>
  );
}
